import { ClassValue } from 'clsx';
import { ReactElement, ReactNode } from 'react';
import {
  TButtonRound,
  TConsultation,
  TConsultationStatus,
  TEscriptsStatus,
  TEscriptsTokenStatus,
  TFileTypes,
  TVisibilityTypes,
  TOrderStatus,
  TConsultationLog,
  TConsultationUserType,
  TLimits,
  TPaymentStatus,
  TOrderHeaderName
} from './types';
import { IPatientDetails, IPatientResponse } from './models/patient-model';
import { IDoctorDetails, IDoctorResponse } from './models/doctor-model';
import { ICouponDetails } from './models/coupon-model';

export interface IGuard {
  children: ReactElement;
}

export interface ILoginResponse {
  access_token: string;
  expiry: number;
  refresh_token: string;
}

export interface IConsultationTypeApiResponse extends IApiResponse {
  data: IConsultationType[];
}

export interface IConsultationType {
  capitalize_name: TConsultation;
  duration: number;
  id: string;
  name: string;
  price: number;
  description: string;
}

export interface IConsultationsResponse extends IApiResponse {
  data: IConsultation[];
}
export interface IConsultation {
  consultation_date_end: Date;
  consultation_date_start: Date;
  consultation_type_id: string;
  created_at: Date;
  doctor_id: string;
  enquiry: string;
  id: string;
  reference_id: number;
  patient_id: string;
  schedule_id: string | null;
  status: TConsultationStatus;
  consultation_type: IConsultationType;
  patient: IPatientResponse;
  doctor: IDoctorResponse;
  notes: string | null;
  consultation_notes: IConsultationNotes[];
}

export interface IConsultationNotes {
  id: string;
  consultation_id: string;
  notes: string;
  is_actioned: boolean;
  created_at: Date;
  updated_at: Date;
}

export interface IConsultationSchedules {
  id: string;
  doctor_id: string;
  resourceId?: string;
  start: Date;
  end: Date;
  title?: string;
  timezone?: string;
}

export interface IConsultationResponse {
  id: string;
  doctor_id: string;
  start_at: Date;
  end_at: Date;
  timezone: string;
}

export type IMenuTypes = IMenuLink | IMenuAtag | IMenuButton | IMenuActions;

export interface IMenuActions extends IMenu {
  actions: IMenuTypes[];
  hoverable?: boolean;
}

export const isMenuActions = (action: any): action is IMenuActions =>
  action.actions instanceof Array;

export interface IMenu {
  // to: To;
  label: string;
  icon?: ReactNode;
  itemRound?: TButtonRound;
  className?: ClassValue;
  divider?: boolean;
  setting?: boolean;
}

export interface IMenuLink extends IMenu {
  to: string;
  state?: { [x: string]: any };
  replace?: boolean;
}

export const isIMenuLink = (action: any): action is IMenuLink => typeof action.to === 'string';

export interface IMenuAtag extends IMenu {
  href: string;
  target?: string;
}

export const isIMenuAtag = (action: any): action is IMenuAtag => typeof action.href === 'string';

export interface IMenuButton extends IMenu {
  onClick: (event: any) => void;
  type?: 'button' | 'submit';
}

export const isIMenuButton = (action: any): action is IMenuButton =>
  typeof action.onClick === 'function';

export interface ISelectOptions {
  value: any;
  label: string;
  disabled?: boolean;
  others?: any;
}

export interface IResources {
  id: string;
  title: string;
  eventColor?: string;
}

export interface IConsultationEvents {
  id: string;
  resourceId?: string;
  start: Date;
  end: Date;
  title: string;
  type?: 'schedules' | 'consultations';
  color?: string;
  patientId?: string;
  doctorId?: string;
  consultationName?: string;
  name?: string;
  status?: TConsultationStatus;
}

export interface ITimeOptionType {
  value: string;
  label: string;
  disabled?: boolean;
}

export interface IUsersResponse {
  contact_no: string;
  created_at: Date | null;
  email: string;
  email_verified_at: Date | null;
  id: string;
  is_super_admin: boolean;
  first_name: string;
  last_name: string;
  full_name: string;
  user_type: IUserTypesResponse | null;
  user_type_id: string | null;
}

export interface IUserTypesResponse {
  id: string;
  name: string;
  status: string;
  no_of_users: string;
  patient_access: boolean;
  doctor_access: boolean;
  consultation_access: boolean;
  product_access: boolean;
  prescription_access: boolean;
  coupon_access: boolean;
  reset_consultation_access: boolean;
  generate_prescription_access: boolean;
  reschedule_access: boolean;
  did_not_attend_access: boolean;
  cancel_access: boolean;
  consultation_logs_access: boolean; // @TODO
  login_patient_access: boolean;
  login_doctor_access: boolean;
  is_deleted: boolean;
  update_wallet_access: boolean;
  dispensing_limit_access: boolean;
  dispensing_interval_access: boolean;
  created_at: Date | null;
  prescription_nova_access: boolean;
  prescription_air_access: boolean;
  view_clinical_notes_access: boolean;
}

export interface IProductType {
  id: String;
  name: string;
  icon: TLimits;
}

export interface IProductResponse {
  brand: string;
  created_at: Date;
  format: string;
  id: string;
  product_id: string;
  product_name: string;
  status: string;
  price: string;
  name: string;
  schedule: string;
  stock: number;
  category: string;
  product_type: IProductType;
  size: number;
  image_url: string | null;
  cost: string;
  quantity: string;
  weight: number;
  // estimate_delivery: string;
  estimate_dispatch: string;
  administration: string;
  strength: string;
}

export interface IProductOrder {
  administration: string;
  brand: string;
  category: string;
  cost: number;
  created_at: Date;
  format: string;
  id: number;
  name: string;
  price: number;
  product_id: string;
  quantity: string;
  route: string;
  schedule: string;
  sku: string;
  status: 'ACTIVE' | 'INACTIVE';
  stock: number;
  strength: string;
  tags: any;
  updated_at: Date;
}

export interface ITabList {
  id?: string;
  name: string;
  show: 'table' | 'details' | 'calendar';
  timezone?: string;
}

export interface IConsultationDocuments {
  id: string;
  type?: string;
  filename: string;
  file_url: string;
  file_key?: string;
  file_type: TFileTypes;
  uploaded_by: string;
  upload_date: Date;
  visibility: TVisibilityTypes;
}

export interface IExtendedDocuments extends IConsultationDocuments {
  consultation: IConsultation;
  consultation_doctor: IDoctorResponse;
}

export interface IProducts {
  id: string;
  type: string;
  brand: string;
  product_name: string;
  stock: number;
  format: string;
  schedule: string;
  price: string;
  is_active: boolean;
  product_type: IProductType;
}

export interface IProductSelection {
  id?: string;
  product_id: string;
  no_of_units: string;
  repeats: string;
  dosage: string;
  frequency: string;
  route: string;
  product?: IProducts;
}

export interface IProductCustom {
  id?: string;
  product_name: string;
  product_details: string;
  product_type: string;
  no_of_units: string;
  repeats: string;
  dosage: string;
  brand_name: string;
  sku: string;
  type: string;
  format: string;
  frequency: string;
  route: string;
}

export interface IPrescriptionDetails {
  id: string;
  reference_id: number;
  patient_id: string;
  consultation_id: string;
  products: IProductSelection[];
  custom_products: IProductCustom[];
  consultation: IConsultationDetails;
  consultation_notes: string;
  treatment_notes: string;
  careteam_notes: string;
  with_escript: boolean;
  has_followup: boolean;
  followup_value: number;
  followup_period: string;
}

export interface IConsultationDetails {
  id: string;
  doctor: IDoctorDetails;
  patient: IPatientDetails;
  start_at: Date;
  end_at: Date;
  consultation_type: IConsultationType;
  enquiry: string;
  // step: TBookingSteps;
  status: TConsultationStatus;
  prescription?: IPrescriptionDetails;
  tga_approval: boolean;
  tga_acceptance: boolean;
  script_approval: boolean;
  documents: IConsultationDocuments[];
}

export interface IConsultationCalendar {
  id: string;
  title: string;
  consultationName: string;
  date: Date;
  start: string;
  name: string;
  doctorId: string;
}

export interface IEscripts {
  id: number;
  script_id: string;
  // patient: string;
  // doctor: string;
  consultation_date: Date;
  product: IProductResponse;
  description: string;
  reference_id: number;
  remaining: number;
  total: number;
  status: TEscriptsStatus;
  tokens: IScriptToken[];
  item: IEscriptsItem;
  expiry: Date;
}

export interface IEscriptsItem {
  administration: string | null;
  category: string;
  cost: number;
  created_at: Date;
  dispensed_at: Date | null;
  doctor_address: string;
  doctor_credentials: string;
  doctor_id: string;
  doctor_name: string;
  doctor_phone: string;
  doctor_signature: string;
  dose: string;
  due_at: Date | null;
  expiry: Date;
  format: string | null;
  frequency: string;
  id: number;
  invoiced_at: Date | null;
  order_id: string | null;
  paid_at: Date | null;
  patient_address: string;
  patient_dob: string;
  patient_email: string;
  patient_id: string;
  patient_name: string;
  patient_phone: string;
  prescriber_no: string;
  price: number;
  product_brand: string;
  product_id: string;
  product_name: string;
  quantity: string;
  route: string;
  schedule: string;
  script_id: string;
  sku: string;
  source: string;
  source_id: string;
  source_ref: string;
  source_time: string;
  status: TEscriptsStatus;
  strength: string | null;
  token_id: string;
  token_no: number;
  total_count: number;
  updated_at: Date;
}

export interface IScriptList {
  category: string;
  created_at: Date;
  doctor_address: string;
  doctor_credentials: string;
  doctor_id: number;
  doctor_name: string;
  doctor_phone: string;
  dose: string;
  expiry: Date;
  frequency: string;
  id: number;
  patient_address: string;
  patient_dob: string;
  patient_email: string;
  patient_id: string;
  patient_name: string;
  patient_phone: string;
  prescriber_no: string;
  price: number;
  product_brand: string;
  product_id: number;
  product_name: string;
  quantity: number;
  remaining: number;
  route: string;
  schedule: string;
  script_id: string;
  sku: string;
  source: string;
  source_id: string;
  source_ref: string | null; // remove null for production
  status: TEscriptsStatus;
  total_count: number;
  token_count: number;
  updated_at: Date;
  tokens: IScriptToken[];
}

export interface IScriptToken {
  token_id: string;
  token_no: number;
  source: string | null; // remove null for production
  source_id: string | null; // remove null for production
  source_ref: string | null; // remove null for production
  script_id: string;
  source_time: Date;
  patient_id: string;
  patient_name: string | null; // remove null for production
  patient_dob: string | null; // remove null for production
  patient_email: string | null; // remove null for production
  patient_phone: string | null; // remove null for production
  patient_address: string | null; // remove null for production
  doctor_id: number;
  doctor_name: string | null; // remove null for production
  doctor_credentials: string | null; // remove null for production
  doctor_phone: string | null; // remove null for production
  doctor_address: string | null; // remove null for production
  prescriber_no: string | null; // remove null for production
  product_id: number;
  product_brand: string | null; // remove null for production
  product_name: string | null; // remove null for production
  dose: string;
  frequency: string;
  route: string;
  quantity: string | null;
  schedule: string | null; // remove null for production
  category: string | null; // remove null for production
  sku: string | null; // remove null for production
  price: number | null;
  status: TEscriptsTokenStatus;
  expiry: Date;
  due_at: Date | null;
  invoiced_at: Date | null;
  paid_at: Date | null;
  pharmacist_id: string | null;
  pharmacist_name: string | null; // remove null for production
  pharmacist_phone: string | null; // remove null for production
  pharmacist_address: string | null; // remove null for production
  dispensed_at: Date | null;
  created_at: Date;
  updated_at: Date | null;
  order_no: number | null;
  prescribed_at: Date;
  ready_at: Date;
  cancelled_at: Date;
  order: IOrderToken | null;
}

export interface IOrderDetails {
  id: number;
  order_id: string;
  patient_id: string;
  patient_email: string | null;
  patient_name: string | null;
  patient_dob: string | null;
  patient_phone: string | null;
  patient_address: string | null;
  status: TOrderStatus;
  invoiced_at: Date | null;
  paid_at: Date | null;
  price: number | null;
  pharmacist_id: string | null;
  pharmacist_name: string | null;
  pharmacist_phone: string | null;
  pharmacist_address: string | null;
  dispensed_at: Date | null;
  created_at: Date;
  updated_at: Date;
  order_notes: string;
}

export interface IOrderToken extends IOrderDetails {
  order_no: null;
  order_ref: null;
  shipit_number: null;
  source: string;
  delivery_street: string;
  delivery_city: string;
  delivery_state: string;
  delivery_postal: string;
  dispensed_products: any[];
  original_price: number;
  unpaid_balance: number;
  wallet_used: number;
  shipping_option: string;
  shipping_fee: number;
  paid_card: string;
  paid_card_ending: string;
  transaction_id: string | null;
  payment_error: string | null;
  is_recompute: boolean;
  is_edit_price: boolean;
  voided_at: Date | null;
  created_by: string;
  pharmacy_id: string | null;
}

export interface IOrdersList extends IOrderDetails {
  tokens: IScriptToken[];
  // for removal later
  email: string | null;
  password: string | null;
  salt: string | null;
  first_name: string | null;
  middle_names: string | null;
  last_name: string | null;
  dob: Date | null;
  phone: string | null;
  address: string | null;
  city: string | null;
  state: string | null;
  postcode: number | null;
  country: string | null;
  medicare_no: string | null;
  medicare_ref: number | null;
  medicare_expiry: Date | null;
}

export interface IOrder {
  category: string;
  created_at: Date;
  doctor_address: string;
  doctor_credentials: string;
  doctor_id: number;
  doctor_name: string;
  doctor_phone: string;
  dose: string;
  expiry: Date;
  frequency: string;
  id: number;
  patient_address: string;
  patient_dob: string;
  patient_email: string;
  patient_id: string;
  patient_name: string;
  patient_phone: string;
  prescriber_no: string;
  price: number;
  product_brand: string;
  product_id: number;
  product_name: string;
  quantity: number;
  remaining: number;
  route: string;
  schedule: string;
  script_id: string;
  sku: string;
  source: string;
  source_id: string;
  source_ref: string;
  status: TEscriptsStatus;
  total_count: number;
  token_count: number;
  updated_at: Date;
  tokens: IScriptToken[];
}

export interface IFileMutation {
  id?: number;
  type?: string;
  filename: string;
  file_url: string;
  filetype?: TFileTypes;
}

export interface IFileCheckResponse {
  success: boolean;
  message: string;
}

export interface IOrderHistoryResponse extends IApiResponse {
  data: IOrderHistory[];
}

export interface IOrderHistory {
  created_at: Date;
  dispensed_at: Date | null;
  // dispensed_products: [];
  id: number;
  invoiced_at: Date | null;
  order_id: string;
  order_no: number;
  order_notes: string;
  order_ref: string | null;
  paid_at: Date | null;
  patient_address: string;
  patient_dob: Date;
  patient_email: string;
  patient_id: string;
  patient_name: string;
  patient_phone: string;
  pharmacist_address: string | null;
  pharmacist_id: string | null;
  pharmacist_name: string | null;
  pharmacist_phone: string | null;
  price: number;
  products: IProductOrder[];
  source: string;
  status: TOrderStatus;
  updated_at: Date;
  wallet_used: number;
  original_price: number;
}

export interface IConsultationTimeline {
  log: TConsultationLog;
  log_date: Date;
  name: string;
  user_type: TConsultationUserType;
  details: string | null;
  consultation: IConsultation | null;
  payment: IPaymentDetails | null;
  coupon: ICouponDetails | null;
}

export interface IPaymentDetails {
  id: string;
  patient_id: string;
  consultation_id: string;
  total: number;
  invoice: string | null;
  error_message: string | null;
  status: TPaymentStatus;
  created_at: Date;
  updated_at: Date;
}

export interface ITransactions {
  created_at: Date;
  updated_at: Date;
  status: number;
  id: number;
  reference_id: string;
  uuid: string;
  disbursement_bank: string | null;
  processing_bank: string | null;
  external_reference: string | null;
  reference_amount: string | null;
  internal_state: string | null;
  internal_status: string | null;
  account_external: {
    account_type_id: number;
    currency: {
      code: string;
    };
  };
  external_account_details: string;
  external_account_details_encrypted: boolean;
  marketplace: {
    group_name: string | null;
    name: string;
    short_name: string | null;
    uuid: string;
    account_details: string;
    account_details_encrypted: boolean;
  };
  first_name: string;
  last_name: string;
  user_email: string;
  user_external_id: string;
  payout_currency: string | null;
  type: string;
  type_method: string;
  batch_id: number;
  cuscal_payment_transaction_id: string | null;
  reference: string;
  state: string;
  user_id: string;
  account_id: string;
  from_user_name: string;
  from_user_id: string;
  refund_state: string | null;
  account_type: string;
  amount: number;
  currency: string;
  country: string | null;
  debit_credit: string;
  item: {
    id: string | null;
    item_number: string | null;
  };
  description: string;
  related: {
    account_to: {
      id: string;
      account_type: string;
      user_id: string;
    };
  };
}

export interface IDispensingLimit {
  dispensing_limit: number;
  icon: TLimits;
  id: string;
  name: string;
  unit: string;
  remaining: number;
}

export interface IMedicineDetails {
  type: string;
  frequency: string;
}

export interface ICurrentMedication {
  name: string;
  dose: string;
  frequency: string;
  reason: string;
}

export interface IConsultationPrescription extends IConsultation {
  id: string;
  patient_id: string;
  consultation_id: string;
  submitted_at: Date;
  created_at: Date;
  updated_at: Date;
  followup_period: string | null;
  followup_value: number;
  set_followup: boolean;
  dispensing_interval: number;
  prescription: {
    id: string;
    patient_id: string;
    consultation_id: string;
    consultation_notes: string;
    treatment_notes: string;
    careteam_notes: string;
    with_escript: boolean;
    submitted_at: Date;
    created_at: Date;
    updated_at: Date;
    dispensing_notes: string;
    followup_period: string;
    followup_value: number;
    has_followup: 0 | 1;
    set_followup: boolean;
    dispensing_interval: boolean;
    prescription_products: IPrescriptionProducts[];
    post_prescription_notes: {
      id: string;
      prescription_id: string;
      notes: string;
      created_at: Date;
      updated_at: Date;
    }[];
  };
}

export interface IPrescriptionProducts {
  id: string;
  prescription_id: string;
  product_id: string;
  no_of_units: string;
  repeats: string;
  dosage: string;
  frequency: string;
  route: string;
  created_at: Date;
  updated_at: Date;
  product: IProductsConsultations;
}

export interface IProductsConsultations {
  id: string;
  product_type_id: string;
  brand: string;
  product_name: string;
  format: string;
  schedule: string;
  price: string;
  stock: number;
  type: string;
  quantity: number;
}

export interface IApiResponse {
  code?: number;
  status: number;
  count: number;
  message: string | null;
}

export interface IEscriptPrescriptionResponse extends IApiResponse {
  data: IEscriptPrescriptionData[];
}

export interface IEscriptPrescriptionData {
  id: string;
  patient_name: string;
  date_of_birth: string;
  active_products: number;
  active_tokens: number;
  summary: IEscriptPrescriptionSummary[];
}

export interface IEscriptPrescriptionSummary {
  product_id: string;
  product: IEscriptPrescriptionProduct;
}

export interface IEscriptPrescriptionProduct {
  id: number;
  product_id: string;
  sku: string;
  brand: string;
  name: string;
  product_type_id: string;
  format: string;
  administration: string;
  route: string;
  strength: string;
  quantity: string;
  schedule: string;
  category: string;
  size: number;
  size_unit: string;
  cost: number;
  price: number;
  image_url: string | null;
  stock: number;
  status: string;
  estimate_dispatch: Date | null;
  weight: number;
  created_at: Date;
  updated_at: Date;
  product_type: IProductType;
  tags: IEscriptPrescriptionProductTag[];
  tokens: {
    id: string;
    status: TEscriptsTokenStatus;
    consultation_no: string;
    doctor: string;
    consultation_date: string;
    expiry: Date;
  }[];
}

export interface IEscriptPrescriptionProductTag {
  id: number;
  name: string;
  icon: string;
  description: string;
  color: string;
  visible: boolean;
  product_id: string;
  order: number;
  created_at: Date;
  updated_at: Date;
}

export interface IEscriptOrdersResponse extends IApiResponse {
  data: IEscriptOrdersData[];
}

export interface IEscriptOrdersData {
  id: string;
  order_no: number;
  patient_id: string;
  patient_name: string;
  date_of_birth: string;
  patient_address: string;
  price: number;
  is_paid: boolean;
  notes: string;
  products: IEscriptOrdersEScriptType[];
  ordered_at: Date;
  status: TOrderStatus;
  is_first_order: boolean;
  delivery_city: string;
  delivery_postal: string;
  delivery_state: string;
  delivery_street: string;
  is_partial: boolean;
  is_aging: boolean;
  updated_at: Date;
}

export interface IEscriptOrdersProducts {
  order_id: string;
  product_id: string;
  product_brand: string;
  product_name: string;
  dose: string;
  frequency: string;
  route: string;
  quantity: string;
  format: string | null;
  administration: string | null;
  strength: string | null;
  category: string;
  schedule: string;
  price: number;
  status: string;
  availability: boolean;
  doctor_id: string;
  doctor_name: string;
  consultation_time: Date;
}

export interface IEscriptOrdersEScriptType extends IEscriptOrdersProducts {
  script_type: IEscriptType;
  token_id: string;
}

export interface IEscriptType {
  initial: string;
  name: string;
}

export interface IEscriptDispenseResponse extends IApiResponse {
  data: IEscriptDispenseData[];
}

export interface IEscriptDispenseData {
  id: string;
  order_no: number;
  patient_name: string;
  date_of_birth: string;
  patient_address: string;
  products: IEscriptDispenseProducts[];
  notes: string | null;
  status: TOrderStatus;
  is_first_order: boolean;
  with_shipit: boolean;
  pharmacy: IPharmacy | null;
  ordered_at: Date;
  is_partial: boolean;
  is_aging: boolean;
  updated_at: Date;
  dispensed_by: string | null;
  price: number;
  is_paid: boolean;
}

export interface IEscriptDispenseProducts {
  order_id: string;
  token_no: number;
  token_id: string;
  product_id: string;
  product_brand: string;
  product_name: string;
  product_type: IDispensingLimit;
  dose: string;
  frequency: string;
  route: string;
  quantity: string;
  format: string | null;
  administration: string | null;
  strength: string | null;
  category: string;
  schedule: string;
  price: number;
  status: TEscriptsTokenStatus;
  availability: boolean;
  doctor_name: string;
  prescribed_at: Date;
  qrcode: string;
  token_url: string;
  script_type: IEscriptType;
  scid: string;
  is_new: boolean | null;
}

export interface IEscriptProductsResponse extends IApiResponse {
  data: IEscriptProductsData[];
}

export interface IEscriptProductsData {
  id: number;
  product_id: string;
  sku: string;
  brand: string;
  name: string;
  product_type_id: string;
  format: string;
  administration: string;
  route: string;
  strength: string;
  quantity: string;
  schedule: string;
  category: string;
  size: number;
  size_unit: string | null;
  cost: number;
  price: number;
  image_url: string | null;
  stock: number;
  status:
    | 'ACTIVE'
    | 'IN_STOCK'
    | 'STOCK_LOW'
    | 'STOCK_ORDERED'
    | 'STOCK_IN_TRANSIT'
    | 'OUT_OF_STOCK'
    | 'INACTIVE'
    | 'DISCONTINUED';
  estimate_dispatch: string | null;
  stock_estimate_time_arrival: string | null;
  weight: number;
  created_at: Date;
  updated_at: Date;
  product_type: IDispensingLimit;
  is_formularly: boolean;
}

export interface IDispensingLimitHistoryResponse extends IApiResponse {
  data: IDispensingLimitHistory[];
}
export interface IDispensingLimitHistory {
  clinical_notes: string;
  created_at: Date;
  doctor: { fullname: string };
  new_dispensing_limit: number;
  old_dispensing_limit: number;
  product_type: IDispensingLimit;
}

export interface IDispensingIntervalHistoryResponse extends IApiResponse {
  data: IDispensingIntervalHistory[];
}
export interface IDispensingIntervalHistory {
  id: string;
  patient_id: string;
  dispensing_interval: number;
  interval_reason: string;
  updated_by: string;
  created_at: Date;
  updated_at: Date;
}

export interface IEscriptOrdersHistoryResponse extends IApiResponse {
  data: IEscriptOrderHistoryData[];
}

export interface IEscriptOrderHistoryData {
  id: string;
  order_no: number;
  patient_name: string;
  date_of_birth: string;
  patient_address: string;
  price: number;
  is_paid: boolean;
  pharmacist_name: string | null;
  pharmacist_phone: string | null;
  pharmacist_address: string | null;
  dispensed_at: Date;
  notes: string | null;
  products: IEscriptOrderHistoryProducts[];
  pharmacy: IPharmacy | null;
  order_date: Date;
  updated_at: Date;
  dispensed_by: string | null;
  status: TOrderStatus;
}

export interface IEscriptOrderHistoryProducts {
  order_id: string;
  product_id: string;
  product_brand: string;
  product_name: string;
  dose: string;
  frequency: string;
  route: string;
  quantity: string;
  format: string | null;
  administration: string | null;
  strength: string | null;
  category: string;
  schedule: string;
  price: number;
  status: TEscriptsTokenStatus;
}

export interface IEscriptOpenOrderResponse extends IApiResponse {
  data: IEscriptOpenOrderData[];
}

export interface IEscriptOpenOrderData {
  id: string;
  reference_id: number;
  consultation_date: Date;
  product: {
    id: string;
    brand: string;
    product_name: string;
    format: string;
    schedule: string;
    price: number;
    is_active: boolean;
  };
  description: string;
  remaining: number;
  expiry: Date;
  tokens: IEscriptOpenOrderTokens[];
  total: number;
  status: TEscriptsStatus;
  product_image: string | null;
  availability: boolean;
  dispatch_days: string | null;
  reach_limit: boolean;
  monthly_order: number;
  product_size: number;
  dispensing_limits: IDispensingLimit[];
  last_order: Date;
  order_available_in: string | null;
  price: number;
}

export interface IEscriptOrderDataResponse extends IApiResponse {
  data: IOrderDetailsData | null;
}

export interface IOrderDetailsData {
  id: string;
  reference_id: string;
  tokens: IOrderDetailsTokens[];
  price: number;
  status: TOrderStatus;
  shipping_option: string;
  shipping_fee: number;
  patient_name: string;
  patient_dob: Date;
  patient_address: string;
  ordered_at: Date;
  is_paid: boolean;
  order_notes: string;
  coupon: ICouponDetails | null;
  is_edit_price: boolean;
  original_price: number;
  delivery_street: string;
  delivery_city: string;
  delivery_state: string;
  delivery_postal: string;
  wallet_used: number;
}

export interface IOrderDetailsTokens {
  id: string;
  product_id: string;
  product_brand: string;
  product_name: string;
  route: string;
  quantity: string;
  format: string;
  administration: string;
  strength: string;
  category: string;
  schedule: string;
  sku: string;
  cost: number;
  price: number;
  total_count: number;
  script_id: string;
  is_refunded: boolean;
  refund_reason: string | null;
}
export interface IEscriptOpenOrderTokens {
  id: number;
  token_id: string;
  token_no: number;
  total_count: number;
  script_id: string;
  product_id: string;
  product_brand: string;
  product_name: string;
  dose: string;
  frequency: string;
  route: string;
  quantity: string;
  format: string | null;
  administration: string | null;
  strength: string | null;
  category: string;
  schedule: string;
  sku: string;
  cost: number;
  price: number;
  status: TEscriptsTokenStatus;
  expiry: Date;
  created_at: Date;
  updated_at: Date;
}

export interface IEscriptCartResponse extends IApiResponse {
  cart: IEscriptCartData[];
  price: number;
}

export interface IEscriptCartData {
  id: string;
  escript: {
    id: string;
    product: {
      id: string;
      brand: string;
      product_name: string;
      format: string;
      schedule: string;
      price: number;
      is_active: boolean;
      product_type_id: IDispensingLimit;
    };
    description: string;
    remaining: number;
    total: number;
    status: TEscriptsStatus;
    product_image: string;
    availability: boolean;
    dispatch_days: string;
    reach_limit: boolean;
    monthly_order: number;
    product_size: number;
    dispensing_limits: number;
    last_order: Date;
    order_available_in: Date;
  };
  quantity: number;
}

export interface IPaymentCardToken {
  token: string;
  user_id: string;
}

export interface IEscriptZaiToken extends IApiResponse {
  data: IPaymentCardToken;
}

export interface IPaymentProp {
  paymentId: string;
  isExist: boolean;
}

export interface IAmendOrderData {
  escript_id: string;
  quantity: number;
  product_name: string;
  product_brand: string;
  format: string | null;
  price: number;
  limit: number;
}

export interface IEScriptAmendOrderItem {
  escript_id: string;
  quantity: number;
}

export interface IRefundTokenResponse extends IEscriptsItem {
  is_refunded: boolean;
  refund_reason: string;
  refund_by: string;
  refund_at: Date;
  product: IEscriptProductsData;
}

export interface IPrintOrder extends IApiResponse {
  data: IEscriptPrintOrderData[];
}

export interface IEscriptPrintOrderData {
  label_base64_string: string;
  label_type: string;
}

export interface IEscriptSingleOrderHistory {
  order_id: number;
  total: number;
  admin_id: string;
  log: string;
  created_at: Date;
  order: IOrderDetailsData | null;
  products: IEscriptProductsData[];
  shipping_fee: number | null;
  processed_by: string | null;
  coupon: ICouponDetails | null;
}

export interface IEscriptSingleOrderHistoryResponse extends IApiResponse {
  data: IEscriptSingleOrderHistory[];
}

export interface IConsultationStrings {
  patientName: string;
  doctorName: string;
  consultationName: string;
  totalAmount: number;
  walletBalance: number;
}

export interface IPharmacyResponse extends IApiResponse {
  data: IPharmacy[];
}

export interface IPharmacy {
  id: string;
  name: string;
  created_at: Date;
  updated_at: Date;
}

export interface IOrderHeaderData {
  orders: number;
  review: number;
  dispensing: number;
  shipping: number;
}

export interface IOrderHeaderDataEntries {
  name: TOrderHeaderName;
  count: number | null;
}
